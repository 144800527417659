import slugify from "slugify";

const parse = (input: string): string => slugify(input.toLowerCase());

export interface AuthorPartial {
  firstName?: string;
  lastName?: string;
}

export const pathForAuthor = ({
  firstName,
  lastName,
}: AuthorPartial): string | never => {
  if (!firstName || !lastName) {
    throw Error(`Invalid author. No first or no last name.`);
  }

  return `/${parse(firstName)}-${parse(lastName)}`;
};

import { Avatar, Box, Typography } from "@mui/material";
import { AuthorPreviewProps } from "./AuthorPreviewInterfaces";
import { useAuthorPreviewStyles } from "./AuthorPreviewStyles";

const AuthorPreview = (props: AuthorPreviewProps): JSX.Element => {
  const classes = useAuthorPreviewStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar src={props.avatar} sx={{ width: 125, height: 125 }} />

      <Typography mt={0.5}>{props.firstName + ` ` + props.lastName}</Typography>
    </Box>
  );
};

export default AuthorPreview;

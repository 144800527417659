import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { staticSeoTexts } from "../../../data/staticSeoTexts";
import { pathForAuthor } from "../../../helpers/general/links/pathForAuthor";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import AuthorPreview from "../../02-molecules/AuthorPreview/AuthorPreview";
import { AuthorsTemplateProps } from "./AuthorsTemplateInterfaces";
import { useAuthorsTemplateStyles } from "./AuthorsTemplateStyles";

const AuthorsTemplate = (props: AuthorsTemplateProps): JSX.Element => {
  const { classes } = useAuthorsTemplateStyles();

  const data = useStaticQuery<GatsbyTypes.AuthorsDataQuery>(graphql`
    query AuthorsData {
      allauthours: allWpUser {
        nodes {
          id
          name
          firstName
          lastName
          avatar {
            url
          }
        }
      }
    }
  `);

  return (
    <Box mb={5}>
      <SeoBasics {...staticSeoTexts.autorInnen} />

      <Box mb={1} mt={2.5}>
        <Typography align="center" mb={2} variant="h1">
          Autor*innen
        </Typography>
      </Box>

      <Grid
        className={classes.wrapper}
        container={true}
        rowSpacing={5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {data.allauthours.nodes.map((author, index) => {
          return (
            <Grid
              key={index}
              item={true}
              display="flex"
              justifyContent="center"
              xs={12}
              sm={6}
              md={4}
            >
              <MuiLink component={GatsbyLink} to={pathForAuthor(author)}>
                <AuthorPreview
                  firstName={author.firstName}
                  lastName={author.lastName}
                  avatar={author.avatar.url}
                />
              </MuiLink>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AuthorsTemplate;

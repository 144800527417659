import { Container } from "@mui/material";
import AuthorsTemplate from "../04-templates/AuthorsTemplate/AuthorsTemplate";

export const CategoryPage = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <AuthorsTemplate />
    </Container>
  );
};

export default CategoryPage;
